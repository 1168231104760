<template>
  <div class="container global-list-main-container">
    <div class="general-balance-all-graph-container">
      <div
        class="global-lists-filters-container special-margin-top"
        id="filter-container"
      >
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="global-lists-filters-container" id="filter-container">
        <b-field label="Filtrar por almacen" label-position="inside">
          <b-select
            placeholder="Filtrar por almacen"
            icon="warehouse"
            v-model="filterWarehouse"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todos los almacenes</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse"
              :key="warehouse.NOMBRE_ALM"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="information-cards-container">
        <InformationCard
          name="Cuentas por cobrar venta (Faltante por cobrar)"
          :value="totalIncomeSales.totalMissing"
          :max="totalIncomeSales.total"
          format="money"
          firstDetailMoney="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Cuentas por cobrar validas (Faltante por cobrar)"
          :firstDetailValue="totalIncomeSales.totalValid"
          secondDetailMessage="Cuentas por cobrar expiradas (Faltante por cobrar)"
          :secondDetailValue="totalIncomeSales.totalExpired"
          class="special-margin"
        />
        <InformationCard
          name="Cuentas por cobrar deudores (Faltante por cobrar)"
          :value="totalIncomeOther.totalMissing"
          :max="totalIncomeOther.total"
          format="money"
          firstDetailMoney="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Cuentas por cobrar validas (Faltante por cobrar)"
          :firstDetailValue="totalIncomeOther.totalValid"
          secondDetailMessage="Cuentas por cobrar expiradas (Faltante por cobrar)"
          :secondDetailValue="totalIncomeOther.totalExpired"
          class="special-margin"
        />

        <InformationCard
          name="Cuentas por cobrar venta (Pagadas)"
          :value="totalIncomeSales.total - totalIncomeSales.totalMissing"
          :max="totalIncomeSales.total"
          format="money"
          firstDetailMoney="money"
          width="half"
        />
        <InformationCard
          name="Cuentas por cobrar deudores (Pagadas)"
          :value="totalIncomeOther.total - totalIncomeOther.totalMissing"
          :max="totalIncomeOther.total"
          format="money"
          firstDetailMoney="money"
          width="half"
        />
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">
            Total por cobrar por cliente o dedudor
          </p>
          <b-table
            :data="clientIncomes"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPage"
            :per-page="perPage"
            default-sort="quantity"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">
                No hay totales por cobrar clientes o deudores
              </div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in clientsMostSalesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="!column.money">
                  {{ props.row[column.field] }}
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="general-balance-all-graph-container-full-width">
        <div
          class="general-balance-graph-container-full global-border-radius global-border-shadow general-balance-graph-container-left"
          v-if="totalChart.series.length > 0"
        >
          <apexchart
            width="95%"
            height="800"
            type="donut"
            :options="totalChart.options"
            :series="totalChart.series"
            :key="totalKey"
          ></apexchart>
        </div>
        <div
          class="general-balance-graph-container-full global-border-radius global-border-shadow general-balance-graph-container-right"
          v-if="totalExpiredChart.series.length > 0"
        >
          <apexchart
            width="95%"
            height="1000"
            type="donut"
            :options="totalExpiredChart.options"
            :series="totalExpiredChart.series"
            :key="totalExpiredKey"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "../../Global/components/InformationCard";
import { round } from "../../../utils/fns";
// @ is an alias to /src
export default {
  name: "AnalyticsIncomes",
  components: {
    InformationCard,
  },
  data() {
    return {
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      filterWarehouse: "",
      filterCategory: "",
      filterSubCategory: "",
      totalIncomeSales: {
        total: 0,
        totalMissing: 0,
        quantity: 0,
        totalExpired: 0,
        totalValid: 0,
      },
      totalIncomeOther: {
        total: 0,
        totalMissing: 0,
        quantity: 0,
        totalExpired: 0,
        totalValid: 0,
      },
      clientIncomes: [],
      clientsMostSalesColumns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
        },
        {
          field: "quantity",
          label: "Cantidad",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "validQuantity",
          label: "Cantidad valida",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "expiredQuantity",
          label: "Cantidad expirada",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
      ],
      perPage: 25,
      currentPage: 1,
      totalChart: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            height: "auto",
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Total por cobrar por cliente o dedudor",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "1.4rem",
                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [],
      },
      totalKey: 1,
      totalExpiredChart: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            height: "auto",
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Total caducado por cliente o dedudor",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "1.4rem",
                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [],
      },
      totalExpiredKey: 1,
    };
  },
  methods: {
    async getAllIncomesInformation() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYINCOMES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });
        if (response && response.totalIncomeSales) {
          this.totalIncomeSales = response.totalIncomeSales;
        }
        if (response && response.totalIncomeOther) {
          this.totalIncomeOther = response.totalIncomeOther;
        }
        if (response && response.clientIncomes) {
          this.clientIncomes = response.clientIncomes;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        await this.getAllIncomesInformation();
      }
    },
    updateSeries() {
      let totalLabels = [];
      let totalSeries = [];
      let totalExpiredLabels = [];
      let totalExpiredSeries = [];
      // Move through all the bank accounts and add the name as the label and the current balance to the series
      for (const singleIncome of this.clientIncomes) {
        totalLabels.push(singleIncome.name);
        totalSeries.push(round(singleIncome.quantity, 2));
        totalExpiredLabels.push(singleIncome.name);
        totalExpiredSeries.push(round(singleIncome.expiredQuantity, 2));
      }
      // Set the char options to the ones we got from the bank accounts
      this.totalChart.options = {
        ...this.totalChart.options,
        labels: totalLabels,
      };
      this.totalExpiredChart.options = {
        ...this.totalExpiredChart.options,
        labels: totalExpiredLabels,
      };
      // Set the series to the ones we got from the bank accounts
      this.totalChart.series = totalSeries;
      this.totalExpiredChart.series = totalExpiredSeries;
      this.totalKey += 1;
      this.totalExpiredKey += 1;
      window.dispatchEvent(new Event("resize"));
    },
  },
  created() {
    moment.locale("es");
  },
  mounted() {
    this.getAllIncomesInformation();
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
  },
  watch: {
    clientIncomes() {
      this.updateSeries();
    },
  },
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
@import "../../Global/style/InitialDashboard.css";
</style>
