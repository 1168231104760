<template>
  <div class="container global-list-main-container">
    <div class="general-balance-all-graph-container">
      <div
        class="global-lists-filters-container special-margin-top"
        id="filter-container"
      >
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="global-lists-filters-container" id="filter-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Filtrar por almacen" label-position="inside">
          <b-select
            placeholder="Filtrar por almacen"
            icon="warehouse"
            v-model="filterWarehouse"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todos los almacenes</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse"
              :key="warehouse.NOMBRE_ALM"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
        Almacen y Proveedor
      </div>
      <hr class="analytics-divider" />
      <div class="information-cards-container">
        <InformationCard
          name="Ordenes de compra (Netas)"
          :value="purchasesAnalyticInformation.total"
          :max="purchasesAnalyticInformation.total"
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de ordenes de compra"
          :firstDetailValue="purchasesAnalyticInformation.quantity"
          secondDetailMessage="Promedio de total en ordenes de compra (Netas)"
          :secondDetailValue="purchasesAnalyticInformation.average"
        />
        <InformationCard
          name="Cancelaciones (Netas)"
          :value="purchasesAnalyticInformation.totalCancellations"
          :max="purchasesAnalyticInformation.totalCancellations"
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de cancelaciones"
          :firstDetailValue="purchasesAnalyticInformation.quantityCancellations"
          secondDetailMessage="Promedio de cancelaciones (Netas)"
          :secondDetailValue="purchasesAnalyticInformation.averageCancellations"
        />
      </div>
      <div class="initial-lists-container">
        <div class="initial-single-list">
          <div class="text-center">
            Filtros disponibles para esta sección Fecha de inicio, Fecha de fin
            y Almacen
          </div>
          <hr class="analytics-divider" />
          <p class="initial-table-name">Total de compras por proveedor</p>
          <div class="initial-single-list-filter"></div>
          <b-table
            :data="providerPurchases"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageProviderPurchase"
            :per-page="perPageProviderPurchase"
            default-sort="quantity"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay compras</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in providerPurchasesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="!column.money">
                  {{ props.row[column.field] }}
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
        <div class="initial-single-list">
          <div class="text-center">
            Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
            Almacen y Cantidad
          </div>
          <hr class="analytics-divider" />

          <p class="initial-table-name">
            Top {{ filterMostBought }} de artículos más comprados
          </p>
          <div class="initial-single-list-filter">
            <b-field
              label="Cantidad de artículos a filtrar"
              label-position="inside"
            >
              <b-input
                placeholder="Ejemplo: 50"
                expanded
                icon="numeric"
                :value="filterMostBought"
                @change.native="filterMostBought = $event.target.value"
                type="number"
                min="0"
                step="1"
              ></b-input>
            </b-field>
          </div>
          <b-table
            :data="articlesPurchases"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageArticlesPurchases"
            :per-page="perPageArticlesPurchases"
            default-sort="quantity"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay compras</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in articlesPurchasesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="column.round">
                  {{ props.row[column.field] }}
                </p>
                <p v-if="!column.money && !column.round">
                  {{ props.row[column.field] }}
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "../../Global/components/InformationCard";
// @ is an alias to /src
export default {
  name: "AnalyticsPurchases",
  components: {
    InformationCard,
  },
  data() {
    return {
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      filterProvider: "",
      filterWarehouse: "",
      filterCategory: "",
      filterSubCategory: "",
      purchasesAnalyticInformation: {
        total: 0,
        quantity: 0,
        average: 0,
        totalCancellations: 0,
        quantityCancellations: 0,
        averageCancellations: 0,
      },
      providerPurchases: [],
      articlesPurchases: [],
      providerPurchasesColumns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
        },
        {
          field: "quantity",
          label: "Cantidad",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalSales",
          label: "Número de compras",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
        },
      ],
      articlesPurchasesColumns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
        },
        {
          field: "description",
          label: "Descripción",
          sortable: true,
          searchable: true,
        },
        {
          field: "quantity",
          label: "Cantidad",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
        },
      ],
      perPage: 50,
      perPageProviderPurchase: 50,
      perPageArticlesPurchases: 50,
      currentPage: 1,
      currentPageProviderPurchase: 1,
      currentPageArticlesPurchases: 1,
      filterMostBought: 50,
    };
  },
  methods: {
    async getAllProvidersInformation() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYPURCHASESDATE", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
          provider: this.filterProvider._id,
          category: this.filterCategory,
          subCategory: this.filterSubCategory,
        });

        if (response) {
          this.purchasesAnalyticInformation = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllPurchasesInformation() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYPROVIDERSLIST", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });

        if (response && response.providersPurchasesArray) {
          this.providerPurchases = response.providersPurchasesArray;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllPurchasesArticlesInformation() {
      try {
        let response = await this.$store.dispatch(
          "GETCOMPANYARTICLESLISTDATE",
          {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouse: this.filterWarehouse,
            quantity: this.filterMostBought,
          }
        );

        if (response && response.mostSold) {
          this.articlesPurchases = response.mostSold;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        await this.getAllProvidersInformation();
        await this.getAllPurchasesInformation();
        await this.getAllPurchasesArticlesInformation();
      }
    },
  },
  created() {
    moment.locale("es");
  },
  mounted() {
    this.getAllProvidersInformation();
    this.getAllPurchasesInformation();
    this.getAllPurchasesArticlesInformation();
  },
  computed: {
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
  },
  watch: {
    filterMostBought() {
      this.getAllPurchasesArticlesInformation();
    },
  },
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
</style>
