<template>
  <div class="container global-list-main-container">
    <div class="general-balance-all-graph-container">
      <div
        class="global-lists-filters-container special-margin-top"
        id="filter-container"
      >
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="global-lists-filters-container" id="filter-container">
        <b-field label="Filtrar por almacen" label-position="inside">
          <b-select
            placeholder="Filtrar por almacen"
            icon="warehouse"
            v-model="filterWarehouse"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todos los almacenes</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse._id"
              :key="warehouse.NOMBRE_ALM"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="information-cards-container">
        <InformationCard
          name="Movimientos manuales pendientes"
          :value="transferAnalyticInformation.totalManualMovementsPending"
          :max="transferAnalyticInformation.totalManualMovementsPending"
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de movimientos manuales pendientes"
          :firstDetailValue="
            transferAnalyticInformation.quantityManualMovementsPending
          "
          secondDetailMessage="Promedio de movimientos manuales pendientes"
          :secondDetailValue="
            transferAnalyticInformation.averageManualMovementsPending
          "
          class="special-margin"
        />
        <InformationCard
          name="Movimientos manuales confirmados"
          :value="transferAnalyticInformation.totalManualMovementsConfirmed"
          :max="transferAnalyticInformation.totalManualMovementsConfirmed"
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de movimientos manuales confirmados"
          :firstDetailValue="
            transferAnalyticInformation.quantityManualMovementsConfirmed
          "
          secondDetailMessage="Promedio de movimientos manuales confirmados"
          :secondDetailValue="
            transferAnalyticInformation.averageManualMovementsConfirmed
          "
          class="special-margin"
        />

        <InformationCard
          name="Transferencias entre almacenes pendientes"
          :value="transferAnalyticInformation.totalTransfersWarehousePending"
          :max="transferAnalyticInformation.totalTransfersWarehousePending"
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de transferencias entre almacenes pendientes"
          :firstDetailValue="
            transferAnalyticInformation.quantityTransfersWarehousePending
          "
          secondDetailMessage="Promedio de transferencias entre almacenes pendientes"
          :secondDetailValue="
            transferAnalyticInformation.averageTransfersWarehousePending
          "
          class="special-margin"
        />
        <InformationCard
          name="Transferencias entre almacenes confirmadas"
          :value="transferAnalyticInformation.totalTransfersWarehouseConfirmed"
          :max="transferAnalyticInformation.totalTransfersWarehouseConfirmed"
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de transferencias entre almacenes confirmadas"
          :firstDetailValue="
            transferAnalyticInformation.quantityTransfersWarehouseConfirmed
          "
          secondDetailMessage="Promedio de transferencias entre almacenes confirmadas"
          :secondDetailValue="
            transferAnalyticInformation.averageTransfersWarehouseConfirmed
          "
          class="special-margin"
        />
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">Transferencias</p>
          <b-table
            :data="allTransfers"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageTransfers"
            :per-page="perPageTransfers"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay transferencias</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in transfersColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>

            <template #footer>
              <div class="has-text-right">
                Total en todas las transferencias:
                {{ transfersInformation.transfersTotal | money("MXN", 2) }}
              </div>
            </template>
          </b-table>
        </div>

        <div class="initial-single-list-full">
          <p class="initial-table-name">Transferencias manuales</p>
          <b-table
            :data="allManualTransfers"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageManualTransfers"
            :per-page="perPageManualTransfers"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">
                No hay transferencias manuales
              </div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in transfersColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>

            <template #footer>
              <div class="has-text-right">
                Total en todas las transferencias:
                {{
                  manualTransfersInformation.transfersTotal | money("MXN", 2)
                }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "../../Global/components/InformationCard";

// @ is an alias to /src
export default {
  name: "AnalyticsTransfers",
  components: {
    InformationCard,
  },
  data() {
    return {
      moment: moment,
      filterWarehouse: "",
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      transferAnalyticInformation: {
        totalManualMovementsConfirmed: 0,
        quantityManualMovementsConfirmed: 0,
        averageManualMovementsConfirmed: 0,
        totalManualMovementsPending: 0,
        quantityManualMovementsPending: 0,
        averageManualMovementsPending: 0,
        totalTransfersWarehousePending: 0,
        quantityTransfersWarehousePending: 0,
        averageTransfersWarehousePending: 0,
        totalTransfersWarehouseConfirmed: 0,
        quantityTransfersWarehouseConfirmed: 0,
        averageTransfersWarehouseConfirmed: 0,
      },
      transfersInformation: {
        transfers: [],
        transfersTotal: 0,
      },
      manualTransfersInformation: {
        transfers: [],
        transfersTotal: 0,
      },
      currentPageTransfers: 1,
      perPageTransfers: 25,
      currentPageManualTransfers: 1,
      perPageManualTransfers: 25,
      transfersColumns: [
        {
          field: "CLAVE_TRAN",
          label: "Clave de movimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FECHA_TRAN_FOR",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "REALIZO_MOV",
          label: "Usuario que realizó el movimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ART_CLAVE_ART",
          label: "Clave de artículo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ART_DESCRIPCION",
          label: "Descripción de artículo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },

        {
          field: "TIPO_FOR",
          label: "Tipo de movimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "SALDO_U",
          label: "Cantidad antes del movimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad movida",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD_FIN",
          label: "Cantidad final",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO_OP",
          label: "Código de movimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "OBJ_ORIGEN",
          label: "Descripción de movimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "OBSER_TRAN",
          label: "Observaciones",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ORIG_ALM",
          label: "Almacen origen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DEST_ALM",
          label: "Almacen destino",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    async getAllTransfersInformation() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYTRANSFERSDATE", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });

        if (response) {
          this.transferAnalyticInformation = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllTransfers() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYTRANSFERS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });

        if (response) {
          this.transfersInformation = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllManualTransfers() {
      try {
        let response = await this.$store.dispatch("GETMANUALCOMPANYTRANSFERS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });

        if (response) {
          this.manualTransfersInformation = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        const promiseArray = [];

        promiseArray.push(this.getAllTransfersInformation());
        promiseArray.push(this.getAllTransfers());
        promiseArray.push(this.getAllManualTransfers());

        await Promise.all(promiseArray);
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateArticlesSales(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
  },
  created() {
    moment.locale("es");
  },
  mounted() {
    this.getAllTransfersInformation();
    this.getAllTransfers();
    this.getAllManualTransfers();
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    allTransfers() {
      const allMovements = [];

      for (const singleTransfer of this.transfersInformation.transfers) {
        let singleTransferWithProps = { ...singleTransfer };
        singleTransferWithProps.FECHA_TRAN_FOR = moment(
          singleTransferWithProps.FECHA_TRAN
        ).format("DD-MM-YYYY HH:mm");
        if (singleTransferWithProps.DESTINO) {
          singleTransferWithProps.DEST_ALM =
            singleTransferWithProps.DESTINO.NOMBRE_ALM;
        }
        if (singleTransferWithProps.ORIGEN) {
          singleTransferWithProps.ORIG_ALM =
            singleTransferWithProps.ORIGEN.NOMBRE_ALM;
        }
        if (singleTransferWithProps.CLAVE_ART)
          singleTransferWithProps.ART_CLAVE_ART =
            singleTransferWithProps.CLAVE_ART.CLAVE_ART;
        if (singleTransferWithProps.CLAVE_ART)
          singleTransferWithProps.ART_DESCRIPCION =
            singleTransferWithProps.CLAVE_ART.DESCRIPCION;
        singleTransferWithProps.TIPO_FOR = "Sin definir";
        if (singleTransferWithProps.TIPO_AC === "I") {
          singleTransferWithProps.TIPO_FOR = "Ingreso";
          singleTransferWithProps.CANTIDAD_FIN =
            singleTransferWithProps.SALDO_U + singleTransferWithProps.CANTIDAD;
        }
        if (singleTransferWithProps.TIPO_AC === "E") {
          singleTransferWithProps.TIPO_FOR = "Egreso";
          singleTransferWithProps.CANTIDAD_FIN =
            singleTransferWithProps.SALDO_U + singleTransferWithProps.CANTIDAD;
        }

        if (singleTransferWithProps.STATUS_TRA === "C") {
          singleTransferWithProps.STATUS_FOR = "Confirmada";
        } else if (singleTransferWithProps.STATUS_TRA === "CA") {
          singleTransferWithProps.STATUS_FOR = "Cancelada";
        } else if (singleTransferWithProps.STATUS_TRA === "T") {
          singleTransferWithProps.STATUS_FOR = "Pendiente";
        }

        allMovements.push(singleTransferWithProps);
      }

      return allMovements;
    },
    allManualTransfers() {
      const allMovements = [];

      for (const singleTransfer of this.manualTransfersInformation.transfers) {
        let singleTransferWithProps = { ...singleTransfer };
        singleTransferWithProps.FECHA_TRAN_FOR = moment(
          singleTransferWithProps.FECHA_TRAN
        ).format("DD-MM-YYYY HH:mm");
        if (singleTransferWithProps.DESTINO) {
          singleTransferWithProps.DEST_ALM =
            singleTransferWithProps.DESTINO.NOMBRE_ALM;
        }
        if (singleTransferWithProps.ORIGEN) {
          singleTransferWithProps.ORIG_ALM =
            singleTransferWithProps.ORIGEN.NOMBRE_ALM;
        }
        if (singleTransferWithProps.CLAVE_ART)
          singleTransferWithProps.ART_CLAVE_ART =
            singleTransferWithProps.CLAVE_ART.CLAVE_ART;
        if (singleTransferWithProps.CLAVE_ART)
          singleTransferWithProps.ART_DESCRIPCION =
            singleTransferWithProps.CLAVE_ART.DESCRIPCION;
        singleTransferWithProps.TIPO_FOR = "Sin definir";
        if (singleTransferWithProps.TIPO_AC === "I") {
          singleTransferWithProps.TIPO_FOR = "Ingreso";
          singleTransferWithProps.CANTIDAD_FIN =
            singleTransferWithProps.SALDO_U + singleTransferWithProps.CANTIDAD;
        }
        if (singleTransferWithProps.TIPO_AC === "E") {
          singleTransferWithProps.TIPO_FOR = "Egreso";
          singleTransferWithProps.CANTIDAD_FIN =
            singleTransferWithProps.SALDO_U + singleTransferWithProps.CANTIDAD;
        }

        if (singleTransferWithProps.STATUS_TRA === "C") {
          singleTransferWithProps.STATUS_FOR = "Confirmada";
        } else if (singleTransferWithProps.STATUS_TRA === "CA") {
          singleTransferWithProps.STATUS_FOR = "Cancelada";
        } else if (singleTransferWithProps.STATUS_TRA === "T") {
          singleTransferWithProps.STATUS_FOR = "Pendiente";
        }

        allMovements.push(singleTransferWithProps);
      }

      return allMovements;
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
</style>
